import * as Sentry from '@sentry/react';
import { Suspense, lazy } from 'react';
import { Outlet, RouterProvider, ScrollRestoration, createBrowserRouter } from 'react-router-dom';

import { RouterErrorBoundaryFallback500Redirect } from '../components/RouterErrorBoundary/RouterErrorBoundary';
import { LoadingGeneric } from '../components/Loading/LoadingGeneric';

import { RedirectTo404 } from './guards/RedirectTo404';
import { RACRouterProvider } from './providers/RACRouterProvider';

const Error404 = lazy(() => import('../pages/public/shared/ErrorPage/ErrorPage'));
const Error500 = lazy(() => import('../pages/public/shared/ErrorPage/ErrorPage'));
const EmissionsClarity = lazy(() => import('../pages/public/share/emissions/clarity/Clarity'));
const EmissionsAccuracy = lazy(() => import('../pages/public/share/emissions/accuracy/Accuracy'));
const EmissionsBreakdown = lazy(() => import('../pages/public/share/emissions/breakdown/Breakdown'));
const EmissionsSummary = lazy(() => import('../pages/public/share/emissions/summary/Summary'));
const EmissionsReport = lazy(() => import('../pages/public/share/reports/Report'));
const DataCollectionRequest = lazy(() => import('../pages/public/share/data-collections/Request'));

const sentryCreateBrowserRouter =
  process.env.NODE_ENV === 'production' ? Sentry.wrapCreateBrowserRouter(createBrowserRouter) : createBrowserRouter;

const router = sentryCreateBrowserRouter(
  [
    {
      element: (
        <RACRouterProvider>
          <ScrollRestoration />
          <Suspense fallback={<LoadingGeneric />}>
            <Outlet />
          </Suspense>
        </RACRouterProvider>
      ),
      ErrorBoundary: RouterErrorBoundaryFallback500Redirect,
      children: [
        {
          path: 'test?',
          children: [
            {
              index: true,
              element: <RedirectTo404 />,
            },
            {
              path: 'emissions/:token',
              children: [
                {
                  path: 'clarity',
                  element: <EmissionsClarity />,
                },
                {
                  path: 'accuracy',
                  element: <EmissionsAccuracy />,
                },
                {
                  path: 'breakdown',
                  element: <EmissionsBreakdown />,
                },
                {
                  path: '',
                  element: <EmissionsSummary />,
                },
              ],
            },
            {
              path: 'reports/:token',
              element: <EmissionsReport />,
            },
            {
              path: 'data-collections/inbound/:requestId',
              element: <DataCollectionRequest />,
            },
          ],
        },
        {
          path: '404',
          element: <Error404 code="404" />,
        },
        {
          path: '500',
          element: <Error500 code="500" />,
        },
        {
          path: '*',
          element: <RedirectTo404 />,
        },
      ],
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  },
);

export function RouterShare() {
  return (
    <RouterProvider
      router={router}
      fallbackElement={null}
      future={{
        v7_startTransition: true,
      }}
    />
  );
}
